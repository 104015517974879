import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import Letmeup1 from "../components/letmeup/letmeup1";
import Letmeup2 from "../components/letmeup/letmeup2";
import Letmeup3 from "../components/letmeup/letmeup3";
import Letmeup4 from "../components/letmeup/letmeup4";
import Letmeup5 from "../components/letmeup/letmeup5";
import Letmeup6 from "../components/letmeup/letmeup6";
import Letmeup7 from "../components/letmeup/letmeup7";
import { Helmet } from "react-helmet";

const tag_title = "렛미업 AI - 샘랩";
const tag_description = "샘랩의 렛미업 AI 페이지 입니다.";
const Containar = styeld.div`
overflow-x:hidden;
`;

// markup
const Letmeup = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/letmeup" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/letmeup" />
      </Helmet>
      <Containar>
        <Letmeup1 />
        <Letmeup2 />
        <Letmeup3 />
        <Letmeup4 />
        <Letmeup5 />
        <Letmeup6 />
        <Letmeup7 />
      </Containar>
    </Layout>
  );
};

export default Letmeup;
