import * as React from "react";
import styled from "styled-components";
import background from "../../images/letmeup/letmeup4-1.png";
import background2 from "../../images/letmeup/letmeup4-2.png";
import background3 from "../../images/letmeup/letmeup4-3.png";
import background4 from "../../images/letmeup/letmeup4-4.png";
import useScrollFadeIn from "../useScrollFadein";

const Containar =  styled.div`
display: flex;
width: 96%;
margin: 8vw 5% 15vw;
justify-content: space-between;
align-items: center;
.letmeup4-img-group{
  width: 71vw;
  margin: 0vw 0 33vw;

  margin-left: -20vw;
  img:first-child {
    width: 16%;
    position: absolute;
    margin-top: 14.5vw;
    margin-left: 33.5vw;
    z-index: 2;
  }
  img:nth-child(2) {
    width: 18%;
    margin-top: 0vw;
    position: absolute;
    margin-left: 37.5vw;
    z-index: 1;
  }
  img:nth-child(3) {
    width: 64vw;
    margin-top: -9vw;
    position: absolute;
    margin-left: 7.5vw;
  }
  
  
}
.letmeup4-text{
  margin-left: 14.5vw;
  .letmeup4-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.6vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
  .letmeup4-subtitle{
    margin-top: 2vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #555555;
  }
  .letmeup4-480subtitle{
    display:none;
  }

}
@media screen and (max-width: 1020px){
  margin: 8vw 2% 15vw;
  .letmeup4-text{
    margin-left: 7%;
    .letmeup4-title {
    font-size: 3.8vw;
     }
    .letmeup4-subtitle{
      font-size: 1.8vw;
    }
  }
}
@media screen and (max-width:460px) {
  flex-direction: column;
  margin-top: 43vw;
  margin-bottom: 50vw;
    .letmeup4-text {
    margin-left: 0;
    width: 100%;
    .letmeup4-title {
      font-size:6.5vw;
    }

    .letmeup4-subtitle{
      display:none;
    }
    .letmeup4-480subtitle{
      display:block;
      margin: 5vw 0 3vw;
      font-size: 4.5vw;
      line-height: 1.2;
      font-family: 'Pretendard-Medium';
      color: #555555;
    }
  }

  .letmeup4-img-group{
    width: 100%;
    margin: 0vw 0 69vw;
    img:nth-child(3) {
      width: 128vw;
    margin-top: 0vw;
    position: absolute;
    margin-left: -33.5vw;
    }
    img:nth-child(2) {
      width: 36vw;
      margin-top: 15vw;
      position: absolute;
      margin-left: 24.5vw;
    }
    img:first-child {
      width: 30vw;
    margin-top: 44.5vw;
    margin-left: 19.5vw;
    }
  }
}
`;

// markup
const Letmeup4 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.6),
  };
  return (
    <Containar>
      <div className="letmeup4-text">
        <span {...animation[0]} className="letmeup4-title">
          TO-DO 스케줄
        </span>
        <p {...animation[1]} className="letmeup4-subtitle">
          매일 공부할 내용들을 TO-DO 스케줄에 등록하고
          <br />
          공유한 친구들과 함께 진척사항을 관리하면서 서로
          <br />
          격려하고 함께 공부할 수 있는 환경을 제공합니다.
        </p>
        <p {...animation[2]} className="letmeup4-480subtitle">
          매일 공부할 내용들을 TO-DO 스케줄에
          <br />
          등록하고 공유한 친구들과 함께
          <br />
          진척사항을 관리하면서 서로 격려하고
          <br />
          함께 공부할 수 있는 환경을 제공합니다.
        </p>
      </div>
      <div className="letmeup4-img-group">
        {/* <img src={background4} /> */}
        <img {...animation[3]} src={background3} />
        <img src={background2} />
        <img src={background} />
      </div>
    </Containar>
  );
};

export default Letmeup4;
