import * as React from "react";
import background from "../../images/letmeup/letmeup5.png";
import background2 from "../../images/letmeup/letmeup5-480.png";
import useScrollFadeIn from "../useScrollFadein";
import styled from "styled-components";

const Containar =styled.div`
display: inline-flex;
width: 96%;
margin:0 5% 5vw;
justify-content: space-between;
align-items: center;
.letmeup5-img-group{
  width: 55vw;
   img:last-child {
    width: 45vw;
    float: right;
    margin-right: 8vw;
  }
  img:first-child {
    display:none;
  }
}
.letmeup5-text{
  margin-left: 14.5vw;
  .letmeup5-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.6vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
  .letmeup5-subtitle{
    margin-top: 2vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #555555;
  }

}
@media screen and (max-width: 1020px){
  margin:0 2% 5vw;
  .letmeup5-text{
    margin-left: 7%;
    .letmeup5-title {
    font-size: 3.8vw;
     }
    .letmeup5-subtitle{
      font-size: 1.8vw;
    }
  }
  .letmeup5-img-group{
   
     img:last-child {
      
      margin-right: 0;
    }
    
  }
}
@media screen and (max-width:460px) {
  flex-direction: column;
  .letmeup5-text {
    margin-left: 0;
    margin-top: 10vw;
    width: 100%;
    .letmeup5-title {
      font-size:6.5vw;
    }

    .letmeup5-subtitle{
      display:block;
      margin: 5vw 0 18vw;
      font-size: 4.5vw;
      line-height: 1.2;
      font-family: 'Pretendard-Medium';
      color: #555555;
    }
  }

  .letmeup5-img-group{
    width: 80vw;
    margin-right: 5%;
    img:last-child {
     display:none;
    }
    img:first-child {
      width: 80vw;
      display:block;
    }
  }

}
`;

// markup
const Letmeup5 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("left", 1, 0.6),
    4: useScrollFadeIn("left", 1, 0.6),
  };
  return (
    <Containar>
      <div className="letmeup5-text">
        <div className="letmeup5-title">
          <span {...animation[0]}>뇌파모니터링과</span>

          <span {...animation[1]}>학습분석</span>
        </div>
        <p {...animation[2]} className="letmeup5-subtitle">
          학습하고 있는 뇌의 집중과 이완의 상태를
          <br />
          실시간으로 모니터링하면서 실질적인 학습
          <br />
          집중도 상태를 학습자가 직접 확인할 수 있습니다.
        </p>
      </div>
      <div className="letmeup5-img-group">
        <img alt="img25" {...animation[4]} src={background2} />
        <img alt="img26" {...animation[3]} src={background} />
      </div>
    </Containar>
  );
};

export default Letmeup5;
