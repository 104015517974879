import * as React from "react";
import styeld from "styled-components";
import background from "../../images/letmeup/letmeup3-1.png";
import background2 from "../../images/letmeup/letmeup3-2.svg";
import background3 from "../../images/letmeup/letmeup3-3.svg";
import useScrollCenter from "../useScrollCenter";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";
const Containar = styeld.div`
display: inline-flex;
width: 90%;
margin: 5vw 5% 0;
justify-content: space-between;
align-items: center;
.letmeup3-img-group{
  width: 40vw;
  img:first-child {
    margin: 0.5vw 0 0 18.5vw;
    width: 5vw;
    position: absolute;
  }
  img:nth-child(2) {
    width: 30%;
    position: absolute;
    margin-left: 6vw;
  }
   img:last-child {
    width: 80%;
    float: right;
  }
  letmeup3-img-group {
    width: 40vw;
  }
}
.letmeup3-text{
  margin-left: 14.5vw;
  .letmeup3-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.6vw;
    line-height: 1.2;
  }
  .letmeup3-subtitle{
    margin-top: 2vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #555555;
  }

}
@media screen and (max-width: 1020px){
  margin: 5vw 2% 0;
  .letmeup3-text{
    margin-left: 7%;
    .letmeup3-title {
    font-size: 3.8vw;
     }
    .letmeup3-subtitle{
      font-size: 1.8vw;
    }
  }
}
@media screen and (max-width: 460px){
  flex-direction: column;

  .letmeup3-text{
    width:100%;
    margin-left: 0;
    .letmeup3-title {
      font-size: 6.5vw;
    }
    .letmeup3-subtitle{
      margin: 5vw 0 15vw;
      font-size: 4.5vw;
      line-height: 1.2;
    }
  
  }

  .letmeup3-img-group {
    img:last-child {
      width: 85vw;
      float: left;
      margin-left: -10vw;
    }
    img:nth-child(2) {
      width:70%;
      margin-top: 8vw;
      margin-left:-16vw;
      z-index:1;
    }
    img:first-child {
      margin: 9.5vw 0 0 12.5vw;
      width: 11vw;
      z-index:1;

    }
  }

}
`;

// markup
const Letmeup3 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("up", 1, 0.6),
    4: useScrollFadeIn("up", 1, 0.5),
  };
  const moveAnimate = {
    0: useScrollCenter("center-none", 1, 0.2),
  };
  return (
    <Containar>
      <div className="letmeup3-text">
        <div className="letmeup3-title">
          <div {...animation[0]}>언제 어디서나</div>
          <div {...animation[1]}>제약 없이</div>
          <div {...animation[2]}> 친구와 선생님을 만나세요.</div>
        </div>
        <p {...animation[3]} className="letmeup3-subtitle">
          컨텐츠/튜더추천, 행동분석 피드백으로
          <br />
          의사소통과 온라인교육의 어려움을 해소합니다.
        </p>
      </div>
      <div className="letmeup3-img-group">
        <img alt={'img19'} {...moveAnimate[0]} src={background3} />
        <img alt={'img20'} {...animation[4]} src={background2} />
        <img alt={'img21'} src={background} />
      </div>
    </Containar>
  );
};

export default Letmeup3;
