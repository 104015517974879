import * as React from "react";
import styled from "styled-components";
import background from "../../images/letmeup/letmeup2.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Containar = styled.div`
display: inline-flex;
width: 96%;
margin: 5vw 2%;
justify-content: space-between;
align-items: center;

   img {
    width: 47vw;
  }

.letmeup2-text{
  margin-left: 17vw;
  .letmeup2-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.5vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
  .letmeup2-subtitle{
    margin-top: 2vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #555555;
  }

}

.show-480{
  display:none;
}
@media screen and (max-width: 1020px){
  .letmeup2-text{
    margin-left: 7%;
    .letmeup2-title {
    font-size: 3.8vw;

     }
    .letmeup2-subtitle{
      font-size: 1.8vw;
    }
  }
}
@media screen and (max-width: 460px){
  flex-direction: column;
  width: 100%;
  margin: 25vw 0%;
  .letmeup2-text{
    width:95%;
    margin-left: 5vw;
    .letmeup2-title {
      font-size: 6.5vw;
   
    }
    .letmeup2-subtitle{
      margin: 5vw 0 15vw;
      font-size: 4.5vw;
      line-height: 1.2;
    }
  
  }

  img{
    width:93%;
  }
  .show-480{
    display:block;
  }
  
}
`;

// markup
const Letmeup2 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("up", 1, 0.6),
    4: useScrollFadeIn("right", 1, 0.6),
  };

  return (
    <>
      <Containar>
        <div className="letmeup2-text">
          <div className="letmeup2-title">
            <span {...animation[0]}> 국내 최초 AI 모션감지</span>
            <span {...animation[1]}>개인 맞춤형 학습 플랫폼</span>
            <span {...animation[2]}>LET ME UP!</span>
          </div>
          <p {...animation[3]} className="letmeup2-subtitle">
            렛미업은 온라인과 오프라인을 연계하여
            <br />
            자율적인 학습관리 및 집중능력이
            <br className="show-480" /> 필요한 학생들에게
            <br />
            효과적인 교육환경을 제공합니다.
          </p>
        </div>

        <img {...animation[4]} src={background} alt={'img18'}></img>
      </Containar>
    </>
  );
};

export default Letmeup2;
