import * as React from "react";
import styeld from "styled-components";
import background from "../../images/letmeup/letmeup7.png";
import background2 from "../../images/letmeup/letmeup7-2.png";
import background3 from "../../images/letmeup/letmeup7-3.png";
import useScrollFadeIn from "../useScrollFadein";
const Containar = styeld.div`
display: inline-flex;
width: 62%;
margin: 5vw 19vw 7vw;
justify-content: space-between;
align-items: center;
.letmeup7-img-group{
  width:35vw;
  img:first-child {
    width:35vw;
  }
}
.letmeup7-text{
  height: fit-content;
    width: fit-content;
    margin-top: 0vw;
    margin-left: 2.5vw;
  .letmeup7-firstTitle{
    font-family: 'Cafe24Shiningstar';
    font-size: 1.6vw;
    margin: 0 0 1vw;
  }
  .letmeup7-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 4vw;
    line-height: 1.2;
  }
  .letmeup7-subtitle{
    margin: 2vw 0 3.6vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #888888;
  }
}

.letmeup7-mobileBtn{
  display:flex;
  width:100%;
  img{
    width:35%;
    margin-right:1vw;
  }
}

.letmeup7-miniText{
  font-family: 'Pretendard-Regular';
  color:#888888;
  font-size: 0.7vw;
  margin: 0.5vw 0;
}

.hide-480{
  display: flex;
  }

.show-480 {
  display:none;
}
@media screen and (max-width: 1020px){
  
  .letmeup7-text{
    margin-left: 7%;
    .letmeup7-firstTitle{
    
      font-size: 1.9vw;
      margin: 0 0 0.8vw;
    }
    .letmeup7-title {
    font-size: 4.4vw;
     }
    .letmeup7-subtitle{
      font-size: 1.6vw;
    }
  }
 
}
@media screen and (max-width:460px) {
  width: 100%;
  margin: 5vw 0vw 50vw;
    justify-content: center;

  .hide-480{
  display: none;
  }

  .show-480 {
    display:block;
    .letmeup7-img-group {
      width: 80vw;
      img:first-child {
        width: 80vw;
      }
    }
    .letmeup7-text{
      margin: 0 auto;
      .letmeup7-firstTitle{
        text-align:center;
        font-size: 6vw;
      }
      .letmeup7-title {
        text-align:center;
        font-size: 10vw;
      }
      .letmeup7-subtitle{
        margin: 4vw auto 9vw;
        color: #888888;
        text-align: center;
        font-size: 3.5vw;
        width: max-content;
      }
    }

    .letmeup7-miniText {
      font-size: 3vw;
      text-align: center;
      margin: 5vw auto 19vw;
    }
    .letmeup7-mobileBtn {
      margin: 0 auto;
      width: 60vw;
      justify-content: space-around;
      img{
        width: 45%;
      }
    }

  }

  
}

`;

// markup
const Letmeup7 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("up", 1, 0),
    4: useScrollFadeIn("up", 1, 0.2),
    5: useScrollFadeIn("up", 1, 0.4),
  };
  return (
    <Containar>
      <div className="hide-480">
        <div className="letmeup7-img-group">
          <img src={background} alt={'img28'}/>
        </div>
        <div className="letmeup7-text">
          <p {...animation[0]} className="letmeup7-firstTitle">
            나의 성장을 도와주는
          </p>
          <p {...animation[1]} className="letmeup7-title">
            LET ME UP
          </p>
          <p {...animation[2]} className="letmeup7-subtitle">
            국내최초 AI 모션감지 / 개인 맞춤형 학습 플랫폼
          </p>
          <div className="letmeup7-mobileBtn">
            <img src={background3} alt={'img29'}/>
            <img src={background2} alt={'img30'}/>
          </div>
          <p className="letmeup7-miniText">*PC, ANDROID, IOS 곧 출시 예정</p>
        </div>
      </div>
      <div className="show-480">
        <div className="letmeup7-text">
          <p {...animation[3]} className="letmeup7-firstTitle">
            나의 성장을 도와주는
          </p>
          <p {...animation[4]} className="letmeup7-title">
            LET ME UP
          </p>
          <p {...animation[5]} className="letmeup7-subtitle">
            국내최초 AI 모션감지 / 개인 맞춤형 학습 플랫폼
          </p>
          <div className="letmeup7-mobileBtn">
            <img src={background3} alt={'img31'}/>
            <img src={background2} alt={'img32'}/>
          </div>
          <p className="letmeup7-miniText">*PC, ANDROID, IOS 곧 출시 예정</p>
        </div>
        <div className="letmeup7-img-group">
          <img src={background} alt={'img33'}/>
        </div>
      </div>
    </Containar>
  );
};

export default Letmeup7;
