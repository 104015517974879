import React from "react";
import styled from "styled-components";
import background from "../../images/letmeup/letmeup1.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Containar = styled.div`
position:relative;
width: 100vw;
height:100vh;

.img-filter {
  position: absolute;
  img {
    z-index:-99;
    width: 100vw;
    height:100vh;
    object-fit:cover;
  }
 
}

.letmeup1-text{
  width:100%;
  height:100vh;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items:flex-start;
  position: absolute;
  margin-left:19vw;
  z-index: 5;
  .letmeup1-title {
    font-family: 'Pretendard-ExtraBold';
    color:#ffffff;
    font-size: 4vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
  .letmeup1-subtitle{
    margin-bottom:  0.7vw;
    font-size: 1.3vw;
    color:#ffffff;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
  }
}
.big-font {
  font-family: 'Pretendard-ExtraBold';
  color:rgba(255,255,255,.3);
  font-size: 15vw;
  position: absolute;
  bottom: -2.2vw;
  right: 0;
  // margin-top: 1vw;
  margin-right: -4vw;
  letter-spacing: -4px;
}
@media screen and (max-width: 1020px) {
  .letmeup1-text {
    margin-left: 7%;
    .letmeup1-title {
      font-size: 7vw;
      display: flex;
      flex-direction: column;
    }
    .letmeup1-subtitle{
      font-size: 3vw;
    }
  }
}
@media screen and (max-width: 460px) {
  .big-font{
    margin-right: 2vw;
  }
  .letmeup1-text {
    height: 100vh;
    overflow: hidden;
    .letmeup1-title {
      font-size: 10.5vw;
      margin-left: 5vw;
      display: flex;
      flex-direction: column;
    }
    .letmeup1-subtitle{
      margin: 32vh 5vw 2vw;
      font-size:4.5vw;
    }
    .big-font {
      font-size: 23vw;
      margin-right: -6vw;
      white-space: nowrap;
      bottom: 0;
      margin-bottom: -3vw;
    }
  }
  .img-filter {
    height:100vh;
    overflow: hidden;
    img {
      width: auto;
      height: 100vh;
      margin-left: -172vw;
    }
  }
}
@media screen and (max-width: 360px) {
  .img-filter {
    img {
      margin-left: -220vw;
    }
  }
}
`;


// markup
const Letmeup1 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("up", 1, 0.6),
  };
  const moveAnimate = {
    0: useScrollMove("up", 1, 0),
  };
  return (
    <Containar>
      <div className="img-filter">
        <img src={background} alt={'img17'}></img>
      </div>
      <div className="letmeup1-text">
        <p {...animation[0]} className="letmeup1-subtitle">
          AI 학습 멘터링 서비스
        </p>
        <div className="letmeup1-title">
          <span {...animation[1]}> 나의 학습을</span>
          <span {...animation[2]}>도와주는</span>
          <span {...animation[3]}> 학습멘토</span>
        </div>
      </div>
      <span className="big-font">LET ME UP</span>
    </Containar>
  );
};

export default Letmeup1;
