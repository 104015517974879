import * as React from "react";
import styled from "styled-components";
import background from "../../images/letmeup/letmeup6-1.png";
import background2 from "../../images/letmeup/letmeup6-2.png";
import useScrollFadeIn from "../useScrollFadein";
const Containar =  styled.div`
display: inline-flex;
width: 100%;
margin: 7vw 0;
justify-content: space-between;
align-items: center;
.letmeup6-img-group{
  width: 55vw;
  img:first-child {
    width: 28%;
    margin-top: 3vw;
    margin-left: 11vw;
    position: absolute;
  }
   img:last-child {
    width: 38vw;
    float: right;
   }
}
.letmeup6-text{
  margin-left: 19vw;
  .letmeup6-title {
    font-family: 'Pretendard-ExtraBold';
    font-size: 2.6vw;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
  }
  .letmeup6-subtitle{
    margin-top: 2vw;
    font-size: 1.25vw;
    line-height: 1.2;
    font-family: 'Pretendard-Medium';
    color: #555555;
  }
}
@media screen and (max-width: 1020px){
  margin:7vw 2% ;
  .letmeup6-text{
    margin-left: 7%;
    .letmeup6-title {
    font-size: 3.8vw;
     }
    .letmeup6-subtitle{
      font-size: 1.8vw;
    }
  }
 
}
@media screen and (max-width:460px) {
  flex-direction: column;
  margin-top: 30vw;
  .letmeup6-text {
    margin-left: 5vw;
    width: 95%;
    .letmeup6-title {
      font-size:6.5vw;
    }

    .letmeup6-subtitle{
      display:block;
      margin: 5vw 0 3vw;
      font-size: 4.5vw;
      line-height: 1.2;
      font-family: 'Pretendard-Medium';
      color: #555555;
    }
  }

  .letmeup6-img-group{
    img:last-child {
      width: 110vw;
      float:left;
      margin: 10vw 10vw 25vw;
    }
    img:first-child {
      width: 72%;
      margin-top: 20vw;
      margin-left: -2vw;
    }
  }
}
`;

// markup
const Letmeup6 = () => {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.2),
    2: useScrollFadeIn("up", 1, 0.4),
    3: useScrollFadeIn("right", 1, 0.6),
    4: useScrollFadeIn("right", 1, 0.6),
  };
  return (
    <Containar>
      <div className="letmeup6-text">
        <div className="letmeup6-title">
          <span {...animation[0]}> 진짜 나를 아는 학습멘토</span>
          <span {...animation[1]}> LET ME UP!</span>
        </div>
        <p {...animation[2]} className="letmeup6-subtitle">
          렛미업을 통해 맞춤형 학습 멘토링을 받고
          <br />
          주도적인 학습 습관을 키워보세요.
        </p>
      </div>
      <div className="letmeup6-img-group">
        <img alt="26" {...animation[4]} src={background2} />
        <img alt="27" src={background} />
      </div>
    </Containar>
  );
};

export default Letmeup6;
